/* eslint-disable */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloLink, from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { getUserTokenFromCookie, removeCookieToken } from './cookies';

import { LOGOUT_ERROR_CODE } from '../utils/constants/auth';
import { ERRORS } from '../utils/constants/error';

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => {
      return kind === 'OperationDefinition' && operation === 'subscription';
    });
};

const url = process.env.REACT_APP_APPSYNC_URL;
const region = 'ap-northeast-1';

/**
 * Create subscription link able to connect to AWS AppSync.
 * @note When `authToken` is `null`, the returned ApolloLink will not be able to
 * connect to AppSync subscriptions.
 * @param {string | null} authToken token obtained on login or null prior to login
 * @returns ApolloLink
 */
const createSubscriptionLink = (authToken) => {
  let auth = {
    type: 'AWS_LAMBDA',
    token: authToken,
  };
  if (process.env.REACT_APP_ENV === 'development') {
    auth = {
      type: 'API_KEY',
      apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
    };
  }

  const httpLink = createHttpLink({ uri: url });

  return ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
  ]);
};

const customFetch = (uri, options) => {
  const json = JSON.parse(options.body);
  const operationName = Array.isArray(json) ? json[0].operationName : json.operationName;
  return fetch(`${uri}?op=${operationName}`, options);
};

/**
 * Creates main link with authorized subscription link when valid auth token provided
 * @note When `authToken` is `null`, the returned ApolloLink will not be able to
 * connect to AppSync subscriptions.
 * @param {string | null} authToken 
 * @returns 
 */
const createMainLink = (authToken) => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API, fetch: customFetch });
  const subscriptionLink = createSubscriptionLink(authToken);
  return ApolloLink.split(
    hasSubscriptionOperation,
    subscriptionLink,
    httpLink,
  );
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.statusCode === 503) {
    // This error is handled in ErrorBoundary component
    const error = new Error(ERRORS.maintenance.message);
    error.name = ERRORS.maintenance.name;
    throw error;
  }

  if (graphQLErrors) {
    if (graphQLErrors.length > 0
      && graphQLErrors[0].extensions
      && graphQLErrors[0].extensions.code
      && graphQLErrors[0].extensions.code === LOGOUT_ERROR_CODE) {
      removeCookieToken();
      // redirect to login page
      window.location.href = '/';
    } else {
      if (process.env.REACT_APP_ENV !== 'production') console.log(graphQLErrors);

      if (window.rollbar && graphQLErrors[0]?.extensions?.code !== 'login_failed') {
        window.rollbar.error(graphQLErrors[0]?.extensions?.code || graphQLErrors[0]?.message,
          graphQLErrors);
      }
    }
  }
  if (networkError) {
    if (process.env.REACT_APP_ENV !== 'production') console.log(`[Network error]: ${networkError}`);

    if (window.rollbar) {
      window.rollbar.error(`[Network error]: ${networkError}`)
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'X-Auth-Token': getUserTokenFromCookie(),
      'X-Auth-Role': 'Staff',
    },
  });
  if (forward) {
    return forward(operation);
  }

  return null;
});

export const createClient = (authToken) => {
  const mainLink = createMainLink(authToken);
  return new ApolloClient({
    link: from([authLink, errorLink, mainLink]),
    cache: new InMemoryCache(),
  });
}
